import {$auth} from "../config/api.config";

class FriendsService{
    async get(){
        const {data} = await $auth.get(`/link`)
        return data
    }

    async create(title){
        const {data} = await $auth.post(`/link/create`, {title})
        return data
    }

    async update(title, id){
        const {data} = await $auth.patch(`/link/update`, {title, id})
        return data
    }

    async delete(id){
        const {data} = await $auth.delete(`/link/delete?id=${id}`)
        return data
    }
}
//eslint-disable-next-line
export default new FriendsService();