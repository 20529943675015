import {useMutation, useQueryClient} from "@tanstack/react-query";

import {toast} from "sonner";
import UserService from "../services/user.service";

export const useLives = () => {

    const queryClient = useQueryClient()
    const {mutate: updateLives} = useMutation({
        mutationKey: ["livesUpdate"],
        mutationFn: async (data) => {
            await UserService.update(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
            queryClient.invalidateQueries({queryKey: ["user"]})
        },
        onSuccess:() => {
            queryClient.invalidateQueries({queryKey: ["user"]})
        }
    })

    return {updateLives};
}