import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import TaskService from "../services/task.service";
import {toast} from "sonner";

export const useTask = (id, language) => {

    const {data: tasks, isLoading:isLoadingTask} = useQuery({
        queryKey: ["task"],
        queryFn: () => TaskService.getAll(id),
    });

    const queryClient = useQueryClient();

    const {mutate: updateTask} = useMutation({
        mutationKey: ["taskUpdate"],
        mutationFn: async ({taskId}) => {
            await TaskService.complete(id, taskId)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: ()=>{
            toast.success(language?.taskCompleted);
            queryClient.invalidateQueries({queryKey: ["task"]})
        },
    })

    return {
        tasks,
        isLoadingTask,
        updateTask
    };
}

