import React from 'react';
import cl from "./actions.module.scss"
import useSound from "use-sound";
import clickSound from "../../../sounds/click.mp3";

const Actions = ({start, send, disabled, less, more}) => {
    const [play] = useSound(clickSound)
    return (
        <div className={cl.block}>
            <button disabled={disabled} className={cl.less} onClick={e => {
                play()
                send("less", e)
            }}>
                <div>
                    <div className={cl.ratio}>
                        <div>x{Math.trunc((1 + ((100 - start) / 100)) * 100) / 100}</div>
                    </div>
                    <div className={cl.title}>{less}</div>
                </div>
            </button>
            <button disabled={disabled} className={cl.more} onClick={e => {
                play()
                send("more", e)
            }}>
                <div>
                    <div className={cl.title}>{more}</div>
                    <div className={cl.ratio}>
                        <div>
                            x{Math.trunc((1 + (start / 100)) * 100) / 100}
                        </div>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default Actions;