import React, {useEffect, useState} from 'react';

import cl from "./withdrawal.module.scss"
import {Link, useNavigate} from "react-router-dom";
import Layout from "../../components/layout/layout";
import {useQuery} from "@tanstack/react-query";
import TranslateService from "../../services/translate.service";
import {useTelegram} from "../../hooks/useTelegram.hook";
import {useWithdrawal} from "../../hooks/useWithdrawal.hook";
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";
import {toast} from "sonner";
import Confirm from "../../components/confirm/confirm";
import {useUser} from "../../hooks/useUser";
import {ru} from "../../language/ru.language";
import {eng} from "../../language/eng.language";

const WithdrawalPage = () => {

    const [play] = useSound(clickSound)
    const [amount, setAmount] = useState(0)
    const navigate = useNavigate()


    const {id, username} = useTelegram()

    const [language, setLanguage] =  useState()

    const {user} = useUser(id, username)

    const {withdrawal: withdrawalSend} = useWithdrawal(setAmount, language)

    useEffect(()=>{
        setLanguage(user?.language === "ru" ? ru : eng)
    }, [user])

    const {data: rule} = useQuery({
        queryKey: ["rule"],
        queryFn: () => TranslateService.getRule(),
    });
    const {data: withdrawal} = useQuery({
        queryKey: ["history"],
        queryFn: () => TranslateService.get(id),
    });

    const [confirm, setConfirm] = useState(false)

    const send = () =>{
        withdrawalSend({id, amount})
    }

    const checkForm = ()=>{
        play()
        if(amount !== 0){
            setConfirm(true)
        }else{
            toast.error( user?.language === "ru" ? "Заполните сумму вывода" : "Fill in the withdrawal amount")
        }
    }
    useEffect(()=>{
        if(id === 0){
            window.location.href = 'https://t.me/nvuttibot';
        }
    }, [id])

    useEffect(() => {
        if(rule?.withdrawal === "0"){
            navigate("/airdrop")
        }
        //eslint-disable-next-line
    }, [rule]);

    return (
        <Layout>
            <Confirm visible={confirm} setVisible={setConfirm} title={user?.language === "ru" ? "Выполнить вывод?" :"Execute the output?"} func={send} language={user?.language}/>
            <div>
                <Link to={"/airdrop"} className={cl.back}>
                    <div className={cl.backBtn}>
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 7C19.5523 7 20 7.44772 20 8C20 8.55228 19.5523 9 19 9V7ZM0.292892 8.70711C-0.0976315 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928932C7.04738 0.538408 7.68054 0.538408 8.07107 0.928932C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65685 15.0711L0.292892 8.70711ZM19 9H1V7H19V9Z"
                                fill="white"/>
                        </svg>
                    </div>
                    <div className={cl.backTitle}>{language?.withdrawalBack}</div>
                </Link>
                <div className={cl.form}>
                    <div className={cl.formTitle}>{language?.withdrawalTitle}</div>
                    <div className={cl.formSubTitle}>{language?.withdrawalMinCount} {rule?.minAmountWithdrawal} {language?.withdrawalTokens}</div>
                    <input type={"number"} value={amount || ""} onChange={e=>setAmount(e.target.value)} className={cl.formInput} placeholder={language?. withdrawalPlaceholder}/>
                    <button className={cl.formBtn} onClick={checkForm}>
                        <div className={cl.formBtn__content}>
                            {language?.withdrawalSend}
                        </div>
                    </button>
                    <div className={cl.formInfo}>{language?.withdrawalSubTitle}</div>
                </div>
                {withdrawal?.processed?.length > 0 && <div className={cl.withdrawals}>
                    {withdrawal?.processed.map(item =>
                        <div className={cl.withdrawalItem}>
                            <div className={cl.withdrawalItemTitle}>{
                                user?.language == "ru"
                                    ? item?.title
                                    : "WDL"
                            } {item?.date?.split("-")?.reverse()?.join(".")}</div>
                            <div className={cl.withdrawalItemStatus}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 4C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5V10C9.00006 10.2652 9.10545 10.5195 9.293 10.707L12.293 13.707C12.4816 13.8892 12.7342 13.99 12.9964 13.9877C13.2586 13.9854 13.5094 13.8802 13.6948 13.6948C13.8802 13.5094 13.9854 13.2586 13.9877 12.9964C13.99 12.7342 13.8892 12.4816 13.707 12.293L11 9.586V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4Z"
                                        fill="#5A6076"/>
                                </svg>
                                <span>{language?.inProcessing}</span>
                            </div>
                        </div>
                    )}
                </div>}
                {withdrawal?.history?.length > 0 &&
                    <div className={cl.history}>
                        <div className={cl.historyTitle}>{language?.historyTitle}</div>
                        <div className={cl.historyItems}>
                            {withdrawal?.history.map(item =>
                                <div className={cl.historyItem}>
                                    <div className={cl.historyItemInfo}>
                                        <div
                                            className={cl.historyItemType}>
                                            {
                                                user?.language == "ru"
                                                ? item?.title
                                                : item?.title === "Перевод" ? "Transfer ": "WDL "
                                            }
                                            {item?.date?.split("-")?.reverse()?.join(".")}</div>
                                        <div className={cl.historyItemAmount}>{item?.amount} NVT</div>
                                    </div>
                                    <div className={cl.historyItemColumn}>
                                        <div className={[cl.historyItemStatus, item?.status === "Отклонено" && cl.historyItemStatusError].join(" ")}>
                                            {
                                                user?.language == "ru"
                                                    ? item?.status
                                                    : item?.status === "Выполнено" ? "Done": "Rejected"
                                            }
                                        </div>
                                        <div className={cl.historyItemUsername}>{item?.recipient}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }

            </div>
        </Layout>
    );
};

export default WithdrawalPage;