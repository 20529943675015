import {$auth} from "../config/api.config";

class MailingService {
    async create(dataFetch){
        const {data} = await $auth.post("/mailing", dataFetch,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    }

}

export default new MailingService();
