import {useMutation, useQueryClient} from "@tanstack/react-query";
import TranslateService from "../services/translate.service";
import {toast} from "sonner";

export const useWithdrawal = (setAmount, language) => {

    const queryClient = useQueryClient();

    const {mutate: withdrawal} = useMutation({
        mutationKey: ["withdrawal"],
        mutationFn: async (data) => {
            await TranslateService.withdrawal(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: async ()=>{
            queryClient.invalidateQueries({queryKey: ["history"]})
            setAmount(0)
            toast.success(language === "ru" ? "Запрос отправлен!" : "The request has been sent!");
        },
    })
    return {withdrawal}
}