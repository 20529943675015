import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import LinkService from "../services/link.service";

export const useLink = () => {

    const queryClient = useQueryClient();

    const {data: links} = useQuery(({
        queryKey: ["link"],
        queryFn: ()=> LinkService.get()
    }))

    const {mutate: updateLink} = useMutation({
        mutationKey: ["linkUpdate"],
        mutationFn: ({title, id})=> LinkService.update(title, id),
        onSuccess: () =>{
            queryClient.invalidateQueries({queryKey: ["link"]})
        }
    })

    const {mutate: deleteLink} = useMutation({
        mutationKey: ["linkDelete"],
        mutationFn: (id)=> LinkService.delete(id),
        onSuccess: () =>{
            queryClient.invalidateQueries({queryKey: ["link"]})
        }
    })

    const {mutate: createLink} = useMutation({
        mutationKey: ["linkCreate"],
        mutationFn: (title)=> LinkService.create(title),
        onSuccess: () =>{
            queryClient.invalidateQueries({queryKey: ["link"]})
        }
    })

    return {
        links,
        updateLink,
        deleteLink,
        createLink
    }
}