import React, {useState} from 'react';
import cl from "./popupCreateLink.module.scss";
import {useLink} from "../../hooks/useLink.hook";

const PopupCreateLink = ({visible, setVisible}) => {

    const [title, setTitle] = useState('');

    const {createLink} = useLink()

    return (
        <div className={visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper} onClick={() => setVisible(false)}>
            <div className={cl.body} onClick={(e)=>e.stopPropagation()}>
                <div className={cl.title}>Создать ссылку</div>
                <input type={"text"} value={title} onChange={(e)=>setTitle(e.target.value)} className={cl.input}/>
                <button className={cl.btn} onClick={()=> {
                    if(title !== "") {
                        createLink(title);
                        setVisible(false)
                    }
                } }>Создать</button>
            </div>
        </div>
    );
};

export default PopupCreateLink;