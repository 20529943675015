import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import BotService from "../services/bot.service";
import {toast} from "sonner";

export const useBot = (reset) =>{

    const queryClient = useQueryClient();

    const {data: botData} = useQuery({
        queryKey: ["bot"],
        queryFn: ()=> BotService.get()
    })

    const {mutate: botUpdate} = useMutation({
        mutationKey: ["updateBot"],
        mutationFn: (data)=> BotService.update(data),
        onSuccess: ()=>{
            toast.success("Данные обновленны")
            reset()
            queryClient.invalidateQueries({queryKey: ["bot"]})
        }
    })

    return {
        botData,
        botUpdate
    }
}