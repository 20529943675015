import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import SettingsService from "../services/settings.service";
import {toast} from "sonner";

export const useSettings = (name) =>{

    const {data: dataSettings} = useQuery({
        queryKey: ["settings"],
        queryFn: ()=> SettingsService.get(name)
    });

    const queryClient = useQueryClient();

    const {mutate: updateSettings} = useMutation({
        mutationKey: ["updateSettings", name],
        mutationFn: async ({value}) => {
            await SettingsService.update({name, value})
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: async ()=>{
            toast.success("Максимальный баланс обновлен")
            queryClient.invalidateQueries({queryKey: ["settings"]})
        },
    })

    return {
        updateSettings,
        dataSettings: dataSettings?.value
}

}