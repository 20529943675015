import React, {useEffect, useState} from 'react';
import cl from "./taskPopup.module.scss";
import {useCreateTask} from "../../../hooks/useCreateTask.hook";


const types = [{type: "video", title: "Видео"}, {type: "invite", title: "Рефералы"}, {type: "subscribe", title: "Подписка"}]

const TaskPopup = ({visible, setVisible, task}) => {

    const [data, setData] = useState(task);
    const {updateTask} = useCreateTask()

    useEffect(() => {
        setData(task)
    }, [task]);

    useEffect(() => {
        if(data?.type === "invite"){
            setData({...data, link: "", chatId: null})
        }
        if(data?.type === "subscribe"){
            setData({...data, friendsQty: null})
        }
        if(data?.type === "video"){
            setData({...data, friendsQty: null, chatId: null})
        }
    }, [data?.type]);

    useEffect(() => {

    }, [data?.language]);

    const send = async() =>{
        await updateTask(data)
        setVisible(false)
    }

    return (
        <div className={visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper} onClick={() => setVisible(false)}>
            <div className={cl.body} onClick={(e) => e.stopPropagation()}>
                <div className={cl.title}>Задание</div>
                <div className={cl.subtitle}>Тип задания</div>
                <div className={cl.selectType}>
                    {types.map(item =>
                        <button key={item?.type} className={item.type === data?.type ? [cl.selectType_btn, cl.selectType_btnActive].join(" ") : cl.selectType_btn} onClick={()=>setData({...data, type: item.type})}>{item.title}</button>
                    )}
                </div>
                <div className={cl.selectType}>
                    <button className={data?.location === "ru" ? [cl.location, cl.locationActive].join(" ") : cl.location} onClick={()=> setData({...data, location: "ru"})}>Русс</button>
                    <button className={data?.location === "eng" ? [cl.location, cl.locationActive].join(" ") : cl.location} onClick={()=> setData({...data, location: "eng"})}>Eng</button>
                    <button className={data?.location === "any" ? [cl.location, cl.locationActive].join(" ") : cl.location} onClick={()=> setData({...data, location: "any"})}>Любой</button>
                </div>
                {
                    (data?.location === "ru" || data?.location === "any") &&
                    <>
                        <div className={cl.name}>Название</div>
                        <input type="text" className={cl.input} value={data?.title} onChange={e=>setData({...data, title: e.target.value})} placeholder={"Название"}/>
                        <div className={cl.name}>Описание</div>
                        <input type="text" className={cl.input} value={data?.description} onChange={e=>setData({...data, description: e.target.value})} placeholder={"Описание"}/>
                    </>
                }
                {
                    (data?.location === "eng" || data?.location === "any") &&
                    <>
                        <div className={cl.name}>Название(eng)</div>
                        <input type="text" className={cl.input} value={data?.titleEnglish} onChange={e=>setData({...data, titleEnglish: e.target.value})} placeholder={"Название(eng)"}/>
                        <div className={cl.name}>Описание(eng)</div>
                        <input type="text" className={cl.input} value={data?.descriptionEnglish} onChange={e=>setData({...data, descriptionEnglish: e.target.value})} placeholder={"Описание(eng)"}/>
                    </>
                }
                <div className={cl.name}>Стоимость</div>
                <input type="number" className={cl.input} value={data?.amount} onChange={e=>setData({...data, amount: e.target.value})} placeholder={"Стоимость"}/>
                <div className={cl.name}>Кол-во выполнений</div>
                <input type="number" className={cl.input} value={data?.maxComplete} onChange={e=>setData({...data, maxComplete: e.target.value})} placeholder={"Кол-во выполнений"}/>

                {data?.type === "invite" &&
                    <>
                        <div className={cl.name}>Количество рефералов</div>
                        <input type="number" className={cl.input} value={data?.friendsQty}  onChange={e => setData({...data, friendsQty: parseInt(e.target.value)})} placeholder={"Количество рефералов"}/>
                    </>
                }
                {data?.type === "video" &&
                    <>
                        <div className={cl.name}>Ссылка на ресурс</div>
                        <input type="text" className={cl.input} value={data?.link}
                               onChange={e => setData({...data, link: e.target.value})}
                               placeholder={"Ссылка на ресурс"}/>
                    </>
                }
                {data?.type === "subscribe" &&
                    <>
                        <div className={cl.name}>ChatId</div>
                        <input type="number" className={cl.input} value={data?.chatId}
                               onChange={e => setData({...data, chatId: parseInt(e.target.value)})}
                               placeholder={"ChatId"}/>
                        <div className={cl.name}>Ссылка на ресурс</div>
                        <input type="text" className={cl.input} value={data?.link}
                               onChange={e => setData({...data, link: e.target.value})}
                               placeholder={"Ссылка на ресурс"}/>
                    </>
                }
                <button className={cl.btn} onClick={send}>Сохранить</button>
            </div>
        </div>
    );
};

export default TaskPopup;