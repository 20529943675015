import {useMutation, useQueryClient} from "@tanstack/react-query";
import TranslateService from "../services/translate.service";
import {toast} from "sonner";

export const useTranslate = (reset, language) =>{
    const queryClient = useQueryClient()
    const {mutate: translate} = useMutation({
        mutationKey: ["translate"],
        mutationFn: async (data) => {
            await TranslateService.translate(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: ()=>{
            reset()
            toast.success(language?.taskTransferred);
            queryClient.invalidateQueries({queryKey: ["user"]})
        },

    })

    return {
        translate
    }
}