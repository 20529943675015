import React, {useEffect, useState} from 'react';

import cl from "./navbar.module.scss"
import {NavLink} from "react-router-dom";
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";
import {useTask} from "../../hooks/useTask";
import {useTelegram} from "../../hooks/useTelegram.hook";
import {useUser} from "../../hooks/useUser";
import {ru} from "../../language/ru.language";
import {eng} from "../../language/eng.language";

const Navbar = () => {

    const [play] = useSound(clickSound)

    const {id, username} = useTelegram()

    const [language, setLanguage] =  useState()

    const {user} = useUser(id, username)

    useEffect(()=>{
        setLanguage(user?.language === "ru" ? ru : eng)
    }, [user])


    const {tasks}  = useTask(id)
    const isActiveTasks = tasks?.filter(x => x.completed === false && x.type !== "invite").length
    return (
        <div className={cl.block}>
            <NavLink to="/friends" className={({ isActive }) => (isActive ? [cl.linkActive, cl.link].join(" ") : cl.link)} onClick={()=>play()}>
                <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 11.5C13.224 11.5 15.8333 8.9279 15.8333 5.75C15.8333 2.5721 13.224 0 10 0C6.77604 0 4.16667 2.5721 4.16667 5.75C4.16667 8.9279 6.77604 11.5 10 11.5ZM14 13.1429H13.5677C12.4844 13.6562 11.2813 13.9643 10 13.9643C8.71875 13.9643 7.52083 13.6562 6.43229 13.1429H6C2.6875 13.1429 0 15.792 0 19.0571V20.5357C0 21.8962 1.11979 23 2.5 23H17.5C18.8802 23 20 21.8962 20 20.5357V19.0571C20 15.792 17.3125 13.1429 14 13.1429Z"
                        fill="#6A6F83"/>
                </svg>
                <div>{language?.friends}</div>
            </NavLink>
            <NavLink to="/tasks"
                     className={({isActive}) => (isActive ? [cl.linkActive, cl.link].join(" ") : cl.link)}
                     onClick={() => play()}>
                <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.8737 0.312092C24.864 0.266645 24.8409 0.224814 24.8072 0.191503C24.7735 0.158192 24.7306 0.134782 24.6836 0.124017C21.5458 -0.611838 14.2959 2.01042 10.368 5.77654C9.66742 6.4431 9.02874 7.16696 8.45921 7.93991C7.24795 7.83714 6.03668 7.92295 5.00434 8.3546C2.09162 9.58428 1.24357 12.7929 1.00732 14.1731C0.993916 14.2486 0.998971 14.3261 1.02208 14.3995C1.0452 14.4729 1.08574 14.5401 1.14052 14.5958C1.19529 14.6516 1.26282 14.6945 1.33778 14.721C1.41274 14.7475 1.49308 14.7569 1.5725 14.7486L6.2499 14.2538C6.25323 14.592 6.2745 14.9299 6.31365 15.2661C6.33718 15.4995 6.44523 15.7177 6.61901 15.8827L8.43028 17.616C8.60249 17.7824 8.82985 17.886 9.07315 17.9089C9.42168 17.9463 9.77195 17.9667 10.1226 17.9701L9.60941 22.451C9.60085 22.5271 9.6108 22.6041 9.63847 22.676C9.66615 22.7478 9.7108 22.8126 9.76893 22.8651C9.82706 22.9176 9.89708 22.9565 9.9735 22.9787C10.0499 23.0009 10.1307 23.0058 10.2094 22.9931C11.6457 22.7721 14.9966 21.9587 16.2711 19.1648C16.7211 18.1746 16.8133 17.0184 16.7093 15.8622C17.5171 15.3158 18.2737 14.7031 18.9706 14.0308C22.9109 10.2703 25.6291 3.47134 24.8737 0.312092ZM15.0395 9.54471C14.6797 9.19982 14.4346 8.76033 14.3353 8.28182C14.2359 7.80331 14.2867 7.30729 14.4813 6.85649C14.6759 6.40569 15.0055 6.02037 15.4284 5.74927C15.8513 5.47817 16.3485 5.33347 16.8572 5.33347C17.3659 5.33347 17.8631 5.47817 18.286 5.74927C18.7089 6.02037 19.0385 6.40569 19.2331 6.85649C19.4277 7.30729 19.4785 7.80331 19.3791 8.28182C19.2798 8.76033 19.0347 9.19982 18.6749 9.54471C18.4363 9.77389 18.153 9.9557 17.8411 10.0797C17.5292 10.2038 17.1948 10.2676 16.8572 10.2676C16.5195 10.2676 16.1852 10.2038 15.8733 10.0797C15.5614 9.9557 15.2781 9.77389 15.0395 9.54471Z"
                        fill="#6A6F83"/>
                    {isActiveTasks > 0 ? <path className={cl.active}
                           d="M13.8451 8.38398C13.9655 8.96352 14.2617 9.49283 14.6929 9.90618C14.9787 10.1806 15.317 10.3973 15.688 10.5449C16.059 10.6924 16.4561 10.7682 16.8566 10.7682C17.2572 10.7682 17.6542 10.6924 18.0253 10.5449C18.3962 10.3973 18.7345 10.1806 19.0203 9.90618C19.4515 9.49283 19.7478 8.96352 19.8681 8.38398C19.9885 7.80428 19.9267 7.2036 19.6916 6.65886C19.4566 6.11438 19.06 5.65238 18.5553 5.32885C18.0508 5.00544 17.4597 4.83398 16.8566 4.83398C16.2536 4.83398 15.6625 5.00544 15.158 5.32885C14.6533 5.65238 14.2567 6.11438 14.0217 6.65886C13.7865 7.2036 13.7248 7.80428 13.8451 8.38398Z"
                           fill="#2C6DFB" stroke="white"/> : ""}
                    <path
                        d="M6.58339 19.9733C6.28375 20.2306 5.80312 20.3309 5.22461 20.4167C3.92488 20.6066 2.77716 19.6438 3.01064 18.5173C3.09977 18.0903 3.36333 17.4917 3.52736 17.3511C3.56322 17.321 3.58708 17.2819 3.59557 17.2395C3.60406 17.197 3.59673 17.1534 3.57464 17.1147C3.55254 17.0761 3.5168 17.0443 3.47249 17.0241C3.42817 17.0038 3.37754 16.9961 3.32778 17.0019C2.60096 17.0781 1.92479 17.3606 1.4069 17.8044C0.121388 18.9073 0 23 0 23C0 23 4.77679 22.8959 6.0623 21.793C6.58153 21.3493 6.91146 20.7688 6.99895 20.1448C7.01919 19.9489 6.74087 19.8322 6.58339 19.9733Z"
                        fill="#6A6F83"/>
                </svg>
                <div>{language?.tasks}</div>
            </NavLink>
            <NavLink to="/"
                     className={({isActive}) => (isActive ? [cl.linkActive, cl.link].join(" ") : cl.link)}
                     onClick={() => play()}>
                <div className={cl.play}>
                    <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.8618 1.26173C15.0556 0.892236 15.4196 0.636428 15.8181 0.590194L20.9189 0.00703986C21.0649 -0.00925307 21.2096 0.00277912 21.3461 0.0426776L21.7883 0.172608C21.86 0.192787 21.9267 0.221052 21.9901 0.255758C22.2192 0.383158 22.3886 0.598157 22.4648 0.859944L22.5926 1.3093C22.6107 1.37047 22.6217 1.43433 22.6296 1.49834C22.6375 1.57883 22.6369 1.66052 22.6277 1.74339L22.0538 6.92629C22.0436 7.0147 22.0233 7.1028 21.9956 7.18655C21.9707 7.25681 21.9406 7.32496 21.9044 7.39026C21.7881 7.60143 21.6108 7.78015 21.3929 7.89796L18.8774 9.26391C18.7839 9.31385 18.6952 9.37847 18.6154 9.45211L10.6184 16.9024C10.1012 17.3845 9.3245 17.3362 8.9611 16.799L7.89367 15.2304C7.82629 15.1322 7.7421 15.0467 7.64554 14.9782L6.10171 13.8936C5.57303 13.5244 5.52555 12.7352 5.99998 12.2096L13.3323 4.08399C13.4047 4.00285 13.4683 3.91271 13.5175 3.81775L14.8618 1.26173Z"
                            fill="white"/>
                        <path
                            d="M1.48341 11.5028C1.99546 10.9825 2.80544 11.0119 3.18199 11.5657L4.22179 13.0965C4.28489 13.1869 4.36155 13.2681 4.44969 13.3346L7.25418 15.4104C7.33642 15.4709 7.40879 15.5445 7.46834 15.628L9.51124 18.4776C9.57669 18.5672 9.65659 18.6451 9.74562 18.7092L11.2521 19.7658C11.7972 20.1484 11.8261 20.9714 11.3141 21.4917L10.7842 22.0301C10.2705 22.552 9.46215 22.521 9.0856 21.9671L8.04418 20.438C8.01743 20.3977 7.9867 20.3599 7.95435 20.3237C7.9124 20.2778 7.86559 20.2368 7.81627 20.1999L5.0134 18.1225C4.93117 18.062 4.85879 17.9884 4.79924 17.9049L2.75473 15.0569C2.68927 14.9673 2.61099 14.8878 2.52034 14.8253L1.01548 13.7671C0.47041 13.3845 0.439855 12.5631 0.953529 12.0412L1.48341 11.5028Z"
                            fill="white"/>
                        <path
                            d="M4.72729 21.1445C4.99676 21.1318 5.24188 20.9923 5.40431 20.7853C5.52242 20.6352 5.59652 20.4504 5.60556 20.2521L5.63315 19.6808C5.65305 19.2104 5.29414 18.8457 4.83116 18.8659C4.36655 18.8878 4.00765 18.5231 4.02917 18.051C4.04907 17.5806 3.69017 17.2159 3.22718 17.2361L2.66655 17.2625C2.20195 17.2844 1.80656 17.6861 1.78666 18.1566C1.77679 18.3902 1.67337 18.6078 1.51531 18.7699C1.35562 18.9337 1.13982 19.0404 0.906774 19.0506C0.442167 19.0725 0.0467835 19.4742 0.0268847 19.9447L0.000918127 20.5143C-0.0114196 20.785 0.101429 21.0215 0.288653 21.1689C0.425824 21.2787 0.604675 21.3385 0.802908 21.3292C1.08241 21.3168 1.32463 21.4444 1.46738 21.652C1.56166 21.7873 1.61262 21.9577 1.6049 22.1441C1.58338 22.6162 1.94228 22.9809 2.40527 22.9607L2.96589 22.9343C3.4305 22.9124 3.82588 22.5107 3.8474 22.0386C3.8673 21.5681 4.26268 21.1664 4.72729 21.1445Z"
                            fill="white"/>
                        <path
                            d="M13.0674 2.95926C13.1186 3.05665 13.1186 3.1736 13.0674 3.271L12.9436 3.50635L12.9416 3.51025C12.9207 3.5505 12.8904 3.59493 12.8514 3.63878L12.8505 3.63984L8.07177 8.93556C7.97135 9.04684 7.80952 9.07489 7.67847 9.00373L5.644 7.89901C5.28037 7.70211 5.02861 7.33224 4.98311 6.92733L4.40919 1.74443C4.39316 1.59608 4.405 1.44903 4.44427 1.31034L4.57214 0.86099C4.592 0.788123 4.61981 0.720341 4.65397 0.655999C4.77935 0.423223 4.99095 0.251033 5.24859 0.173654L5.69082 0.0437237C5.75103 0.0253588 5.81388 0.0141845 5.87687 0.00615751C5.95609 -0.00189102 6.03648 -0.00124822 6.11804 0.00808593L11.2188 0.59124C11.3059 0.601617 11.3926 0.622185 11.475 0.650402C11.5441 0.67564 11.6112 0.706276 11.6755 0.743061C11.8833 0.861188 12.0592 1.04141 12.1751 1.26277L13.0674 2.95926Z"
                            fill="white"/>
                        <path
                            d="M14.2614 14.8938C14.1207 14.7628 14.1207 14.5374 14.2614 14.4063L18.7789 10.1976C18.9114 10.0742 19.1173 10.0833 19.2387 10.2179L21.0369 12.2107C21.5114 12.7362 21.4639 13.5254 20.9352 13.8947L19.3914 14.9793C19.2948 15.0478 19.2106 15.1333 19.1432 15.2314L18.0758 16.8001C17.7124 17.3373 16.9357 17.3855 16.4185 16.9034L14.2614 14.8938Z"
                            fill="white"/>
                        <path
                            d="M15.7229 21.4927C15.2108 20.9724 15.2397 20.1494 15.7848 19.7668L17.2913 18.7103C17.3803 18.6461 17.4602 18.5683 17.5257 18.4787L19.5686 15.6291C19.6281 15.5455 19.7005 15.472 19.7827 15.4115L22.5872 13.3357C22.6754 13.2692 22.752 13.188 22.8151 13.0975L23.8549 11.5668C24.2315 11.0129 25.0415 10.9835 25.5535 11.5038L26.0834 12.0423C26.5971 12.5642 26.5665 13.3856 26.0214 13.7682L24.5166 14.8264C24.4769 14.8535 24.4396 14.8848 24.4041 14.9176C24.3589 14.9603 24.3185 15.0078 24.2822 15.0579L22.2377 17.9059C22.1781 17.9895 22.1057 18.063 22.0235 18.1235L19.2206 20.2009C19.1325 20.2674 19.0542 20.347 18.9927 20.4391L17.9513 21.9682C17.5748 22.522 16.7664 22.5531 16.2527 22.0311L15.7229 21.4927Z"
                            fill="white"/>
                        <path
                            d="M24.8583 17.5087C25.0621 17.6737 25.1994 17.9228 25.2118 18.1966C25.2334 18.6687 25.6287 19.0705 26.0917 19.0907C26.5563 19.1125 26.9517 19.5143 26.9732 19.9864L26.9992 20.556C27.0191 21.0265 26.6602 21.3911 26.1956 21.3693C26.0121 21.3614 25.8445 21.4132 25.7113 21.509C25.507 21.6541 25.3814 21.9002 25.3936 22.1842C25.4028 22.3856 25.3439 22.5673 25.2358 22.7067C25.0908 22.8969 24.858 23.0116 24.5916 22.9991L24.031 22.9727C23.568 22.9525 23.1726 22.5507 23.1511 22.0786C23.1411 21.8418 23.0361 21.6226 22.8749 21.4603C22.7153 21.2997 22.5012 21.1946 22.2712 21.1846C21.8082 21.1644 21.4128 20.7626 21.3913 20.2905L21.3654 19.7209C21.3455 19.2504 21.7044 18.8858 22.1674 18.906C22.632 18.9278 22.9909 18.5632 22.9693 18.0911C22.9494 17.6206 23.3083 17.256 23.7713 17.2762L24.3336 17.3042C24.5287 17.3134 24.7106 17.3887 24.8583 17.5087Z"
                            fill="white"/>
                    </svg>
                </div>
            </NavLink>
            <NavLink to="/faq"
                     className={({isActive}) => (isActive ? [cl.linkActive, cl.link].join(" ") : cl.link)}  onClick={()=>play()}>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M0.867415 0.872237C0.312018 1.43072 0 2.18819 0 2.978V19.5357C0 20.9175 1.73062 21.5272 2.59 20.4486L5.09923 17.2941C5.35446 16.9725 5.74108 16.7851 6.15031 16.7851H18.0385C18.8239 16.7851 19.5772 16.4714 20.1326 15.9129C20.688 15.3544 21 14.5969 21 13.8071V2.978C21 2.18819 20.688 1.43072 20.1326 0.872237C19.5772 0.313753 18.8239 0 18.0385 0H2.96154C2.17609 0 1.42281 0.313753 0.867415 0.872237ZM13.0792 12.0257C13.2071 12.3841 13.5431 12.6267 13.9218 12.6267C14.5323 12.6267 14.9597 12.0202 14.7516 11.4431C14.5403 10.8573 14.3342 10.3005 14.1331 9.77249C13.81 8.89998 13.4916 8.07387 13.1777 7.29418C12.8731 6.51448 12.5685 5.77191 12.2639 5.06647C12.0507 4.57288 11.8331 4.08383 11.6109 3.59932C11.4365 3.21908 11.0568 2.978 10.6404 2.978H10.3566C9.94018 2.978 9.56043 3.21908 9.38607 3.59932C9.16389 4.08383 8.94623 4.57288 8.73309 5.06647C8.42848 5.77191 8.11925 6.51448 7.8054 7.29418C7.50079 8.07387 7.18694 8.89998 6.86386 9.77249C6.66395 10.3124 6.45696 10.8825 6.24291 11.4828C6.04392 12.0409 6.4567 12.6267 7.04629 12.6267C7.40973 12.6267 7.73349 12.3958 7.85355 12.0508L8.04079 11.5129C8.09119 11.3744 8.14098 11.2352 8.19016 11.0954C8.34478 10.656 8.75499 10.3573 9.21853 10.3573H11.7092C12.1727 10.3573 12.5825 10.6562 12.7394 11.0948C12.788 11.2307 12.8371 11.3654 12.8869 11.499C12.952 11.6735 13.0161 11.8491 13.0792 12.0257ZM9.98465 5.99856C10.1615 5.55889 10.7639 5.5599 10.9387 6.00037C11.0169 6.19744 11.0987 6.40594 11.1839 6.62587C11.3783 7.12758 11.5772 7.65673 11.7807 8.21333C11.9093 8.56523 11.6494 8.93711 11.2765 8.93711H9.65121C9.27837 8.93711 9.01838 8.56522 9.14636 8.21308C9.34772 7.65897 9.54227 7.13454 9.73002 6.63979C9.81817 6.41523 9.90305 6.20148 9.98465 5.99856Z"
                          fill="#6A6F83"/>
                </svg>
                <div>FAQ</div>
            </NavLink>
            <NavLink to="/airdrop"
                     className={({isActive}) => (isActive ? [cl.linkActive, cl.link].join(" ") : cl.link)}  onClick={()=>play()}>
                <img src="/image/icons/airdrop.svg" alt="AirDrop"/>
                <div>{language?.finance}</div>
            </NavLink>
        </div>
    );
};

export default Navbar;