export const eng = {
    bet: "Your bet",
    more: "More",
    less: "Less",
    bonus: "Bonus",
    bonusTitle: "Your daily Bonus",
    bonusGet: "Get",
    day: "Day",
    wallet: "Wallet",
    connectWallet: "Connect wallet",
    disconnectWallet: "Unbind",
    min: "Min",
    max: "Max",
    faq: "FAQ",
    friends: "Friends",
    finance: "Finance",
    tasks: "Tasks",
    tasksTitle: "Get bonuses for tasks!",
    taskCheck: "Check",
    friendsTitle: "Get 1000 tokens for each invited friend!",
    friendsRef: "Copy the referral link",
    translate: "Translate",
    translateTitle: "Share tokens",
    translateBtn: "Share",
    withdrawalBtn: "Withdraw NVT to wallet",
    withdrawalBack: "Go back",
    withdrawalTitle: "Withdrawal of funds",
    withdrawalMinCount: "Minimum withdrawal amount",
    withdrawalTokens: "tokens",
    withdrawalPlaceholder: "Withdrawal amount",
    withdrawalSubTitle: "withdrawal processing takes up to 3 days",
    withdrawalDisabled: "Deposits and withdrawals are not available at the moment",
    withdrawalMinAmount: "Minimum withdrawal amount",
    withdrawalSend: "Send a request",
    faq1Title: "Play and earn coins",
    faq2Title: "Two game modes: clicker and guess the number",
    faq2Subtitle: "Take a chance to win or recover if you lose",
    faq3Title: "Guess the number!",
    faq3Subtitle: "Enter your bet amount, wait for the game number, and predict whether it will be higher or lower",
    faq4Title: "Went all-in and lost your entire deposit?",
    faq4Subtitle: "Recover in the clicker mode and build a new balance",
    faq5Title: "Complete tasks to increase your deposit",
    faq6Title: "Invite friends to play and earn bonuses for each friend",
    faq6Subtitle: "Go to the Friends tab and invite new players using your referral link!",
    faq7Title: "You can transfer NVT to your friends",
    faq8Title: "Earn stars",
    faq8Subtitle: "For every 10k NVT accumulated, receive stars to purchase unique game bonuses",
    faq9Title: "Connect your wallet and withdraw funds to your crypto wallet",
    faq10Title: "Don't miss a day and claim your daily bonus! Enjoy the game!",
    faqNext: "Continue",
    faqPlay: "Play",
    copyLink: "The link has been copied!",
    usernameCopy: "Username has been copied!",
    check: "Сheck",
    minBet: "The minimum bet is 100 NVT",
    enoughTokens: "Not enough funds",
    bonusReceived: "The bonus has been received",
    taskCompleted: "Task completed",
    taskTransferred: "Tokens have been transferred",
    fillForm: "Fill out the form",
    transferMake: "Make a transfer?",
    historyTitle: "History of operations",
    transferType: "transfer",
    withdrawalType: "withdrawal",
    requestSent: "The request has been sent!",
    inProcessing: "In processing",
    starsTitle:"you already have",
    stars: "stars!",
    starsText: "Earn NVT, get stars and buy bonuses!",
    starsBonus:"Bonuses",
    starsBack:"back",
    starsNoTask:"In the next update, there will be bonuses that can be bought with accumulated stars",
}