import {Route, Routes} from 'react-router-dom'
import {privateRoutes, publicRoutes} from "../routes";
import {useContext} from "react";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
const AppRouter = observer(() => {
    const {admin} = useContext(Context);
    return (

        <Routes>
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component/>}/>
            )}
            {admin.isAuth && privateRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component/>} />
            )}
        </Routes>
    );
});

export default AppRouter;