import {useMutation} from "@tanstack/react-query";
import MailingService from "../services/mailing.service";
import {toast} from "sonner";

export const useMailing = (reset) => {

    const {mutate: sendMailing} = useMutation({
        mutationKey: ["mailing"],
        mutationFn: (data)=> MailingService.create(data),
        onSuccess: ()=> {
            reset()
            toast.success("Рассылка закочена")
        }
    })

    return{
        sendMailing
    }
}