import axios from "axios";


// export const httpUrl = "http://localhost:5002/api";
// export const appUrl = "http://localhost:5002";
// export const httpUrl = "http://78.140.243.25:5002/api";
// export const appUrl = "http://78.140.243.25:5002";
export const httpUrl = "https://nvutti.tech/api";
export const appUrl = "https://nvutti.tech";


const $auth = axios.create({
    baseURL: httpUrl,
    origin: appUrl,
    withCredentials: true,
    credentials: 'include',
})
const $host = axios.create({
    origin: appUrl,
    withCredentials: true,
    credentials: 'include',
})


const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
}

$auth.interceptors.response.use((config)=>{
    return config
}, (async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401) {
        try {
            const {data} = await $auth.post(`/admin/refresh`)
            localStorage.setItem("accessToken", data.accessToken)
            return $auth.request(originalRequest)
        }catch (e) {
            console.log("Пользователь не авторизован")
        }

    }
}))
$auth.interceptors.request.use(authInterceptor)


export { $auth, $host };

