import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import UserService from "../services/user.service";

export const useUser = (id, username) =>{

    const queryClient = useQueryClient()

    const {data, isLoading} = useQuery({
        queryKey: ["user"],
        queryFn: () => UserService.get(id, username)
    })

    const {mutate: adCreate} = useMutation({
        mutationKey: ["userAd"],
        mutationFn: (adId)=> UserService.adCreate(adId, id)
    })

    const {mutate: updateLanguage} = useMutation({
        mutationKey: ["userLanguage"],
        mutationFn: (data)=> UserService.updateLanguage({id, ...data}),
        onSuccess: ()=>{
            queryClient.invalidateQueries({queryKey: ["user"]})
        }
    })

    return {user: data, userLoading: isLoading, adCreate, updateLanguage};
}