import React, { useEffect, useState } from 'react';
import Layout from "../../components/layout/layout";
import useImagePreloader from "../../hooks/useImagePreloader";
import cl from "./faq.module.scss";

import useSound from "use-sound";
import { appUrl } from "../../config/api.config";
import { useTelegram } from "../../hooks/useTelegram.hook";
import { useUser } from "../../hooks/useUser";
import { eng } from "../../language/eng.language";
import { ru } from "../../language/ru.language";
import clickSound from "../../sounds/click.mp3";



const FaqPage = () => {

    const {id, username} = useTelegram()

    const [language, setLanguage] =  useState()

    const {user, updateLanguage} = useUser(id, username)


    useEffect(()=>{
        setLanguage(user?.language === "ru" ? ru : eng)
    }, [user])


    const pages = [
        {
            step: 1,
            title: language?.faq1Title,
            image: "/image/faq/01.png"
        },
        {
            step: 2,
            title: language?.faq2Title,
            subtitle: language?.faq2Subtitle,
            image: "/image/faq/02.png"
        },
        {
            step: 3,
            title: language?.faq3Title,
            subtitle: language?.faq3Subtitle,
            image: "/image/faq/03.png"
        },
        {
            step: 4,
            title: language?.faq4Title,
            subtitle: language?.faq4Subtitle,
            image: "/image/faq/04.png"
        },
        {
            step: 5,
            title: language?.faq5Title,
            image: "/image/faq/05.png"
        },
        {
            step: 6,
            title: language?.faq6Title,
            subtitle: language?.faq6Subtitle,
            image: "/image/faq/06.png"
        },
        {
            step: 7,
            title: language?.faq7Title,
            image: "/image/faq/07.png"
        },
        {
            step: 8,
            title: language?.faq8Title,
            subtitle: language?.faq8Subtitle,
            image: "/image/faq/08.png"
        },
        {
            step: 9,
            title: language?.faq9Title,
            image: "/image/faq/09.png"
        },
        {
            step: 10,
            title: language?.faq10Title,
            subtitle: language?.faq10Subtitle,
            image: "/image/faq/10.png"
        },
        
    ]

    const [play] = useSound(clickSound)
    useImagePreloader(pages.map(item => item.image))

    const [step, setStep] = useState(1);
    useEffect(()=>{
        if(id === 0){
            window.location.href = 'https://t.me/nvuttibot';
        }
    }, [id])

    const handlerStep = () => {
        play()
        if(step < pages.length) {
            setStep(prev => prev + 1)
        }else{
            window.location.replace(appUrl);
        }
    }

    return (
        <Layout className={cl.layout}>
            <div
                className={[cl.language, user?.language === "ru" ? cl.languageLeft : cl.languageRight].join(" ")}
                onClick={() => {
                    updateLanguage({language: user?.language === "ru" ? "eng" : "ru"})
                }}
            >
                {user?.language === "ru" ? "RUS" : "ENG"}
            </div>
            <img className={cl.cover} src={"/image/faq/cover.svg"} alt={"cover"}/>
            <div className={cl.imageWrapper}>
                <img className={cl.image} src={pages[step - 1].image} alt={pages[step - 1].title}/>
            </div>
      
            <div className={cl.desc}>
                <h3 className={cl.title}>
                    {pages[step-1].title}
                </h3>
                {pages[step-1].subtitle && <p className={cl.subtitle}>{pages[step-1].subtitle}</p>}
            </div>
            <div className={cl.row}>
                <div className={cl.dots}>
                    {new Array(pages.length).fill(0).map((_,i) =><span className={i+1===step && cl._active}></span>)}
                </div>
                <button className={cl.next} onClick={handlerStep}>{step===pages.length ? language?.faqPlay : language?.faqNext}</button>
            </div>
        </Layout>
    );
};

export default FaqPage;