export const ru = {
    bet: "Ваша ставка",
    more: "Больше",
    less: "Меньше",
    bonus: "Бонус",
    bonusTitle: "Ваш ежедневный бонус",
    bonusGet: "Получить",
    day: "День",
    wallet: "Кошелек",
    connectWallet: "Подключить кошелек",
    disconnectWallet: "Отвязать",
    min: "Мин",
    max: "Макс",
    faq: "FAQ",
    friends: "Друзья",
    finance: "Финансы",
    tasks: "Задания",
    tasksTitle: "Получи бонусы за задания!",
    taskCheck: "Проверить",
    friendsTitle: "Получай 1000 токенов за каждого приглашенного друга!",
    friendsRef: "Скопировать реферальную ссылку",
    translate: "Перевести",
    translateTitle: "Поделиться токенами",
    translateBtn: "Поделиться",
    withdrawalBtn: "Вывести NVT на кошелек",
    withdrawalBack: "Вернуться назад",
    withdrawalTitle: "Вывод средств",
    withdrawalMinCount: "Минимальная сумма вывода",
    withdrawalTokens: "токенов",
    withdrawalPlaceholder: "Сумма вывода",
    withdrawalSubTitle: "обработка вывода занимает до 3х дней",
    withdrawalDisabled: "На данный момент пополнение и вывод средств недоступны",
    withdrawalMinAmount: "Минимальная сумма вывода",
    withdrawalSend: "Отправить запрос",
    faq1Title: "Играйте и зарабатывайте монеты",
    faq2Title: "Два режима игры:кликер и угадай число",
    faq2Subtitle:"Возможность рискнуть и выиграть или отыграться в случае проигрыша",
    faq3Title: "Угадай число!",
    faq3Subtitle:"Введите размер ставки, дождитесь число от игры и предугадайте какое выпадет число - больше или меньше",
    faq4Title: "Поставили all-in и проиграли весь депозит?",
    faq4Subtitle:"Отыграйтесь в кликере и заработайте новый банк. В режиме кликера",
    faq5Title:"Выполняйте задания для увеличения депозита",
    faq6Title:"Приглашайте друзей в игру и получайте бонусы за каждого друга",
    faq6Subtitle:"Переходи во вкладку Друзья и приглашай по реферальной ссылке новых игроков!",
    faq7Title:"Есть возможность перевести своим друзьям NVT",
    faq8Title:"Получайте звезды",
    faq8Subtitle:"За каждые 10к накопленных NVT выдаются звезды для покупки уникальных игровых бонусов",
    faq9Title:"Подключите кошелек и выводите средства на свой крипто кошелек",
    faq10Title:"Не пропускайте ни дня и получайте ежедневный бонус! Приятной игры!",
    faqNext: "Продолжить",
    faqPlay:"Играть",
    copyLink: "Ссылка скопирована!",
    usernameCopy: "Username скопирован!",
    check: "Проверить",
    minBet: "Минимальная ставка 100 NVT",
    enoughTokens: "Недостаточно  средств",
    bonusReceived: "Бонус получен",
    taskCompleted: "Задание выполнено",
    taskTransferred: "Токены переведены",
    fillForm: "Заполни форму",
    transferMake: "Выполнить перевод?",
    historyTitle: "История операций",
    transferType: "Переводд",
    withdrawalType: "Вывод",
    requestSent: "Запрос отправлен!",
    inProcessing: "В обработке",
    starsTitle:"у вас уже",
    stars:"звезд!",
    starsText:"Зарабатывайте NVT, получайте звезды и покупайте бонусы!",
    starsBonus:"Бонусы",
    starsBack:"назад",
    starsNoTask:"В ближайшем обновлении тут появятся бонусы, которые можно будет купить за накопленные звезды",
}