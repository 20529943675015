import cl from "./bonus.module.scss";
import Countdown from "react-countdown";
import {Link} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import useSound from "use-sound";
import clickSound from "../../../sounds/click.mp3";

const Bonus = ({active, title}) => {
    const queryClient = useQueryClient();
    const [play] = useSound(clickSound)

    let moscowOffset = 3 * 60; // в минутах
    const userOffset = new Date().getTimezoneOffset();
    const offsetFromMoscow = (moscowOffset + userOffset) / 60; // переводим в часы

    let now = new Date();

    if(offsetFromMoscow<0 && offsetFromMoscow>-7){
        now.setDate(now.getDate() + 1);
    }

    now.setHours(offsetFromMoscow >= 0 ? (24-offsetFromMoscow) :(-1 * offsetFromMoscow), 0,0,0);
    const completed =() =>{
        queryClient.invalidateQueries({queryKey: ["user"]})
    }


    const renderer = ({ hours, minutes, seconds, completed }) => {
        return <span>{hours<10 ? "0" + hours : hours}:{minutes<10 ? "0" + minutes : minutes}:{seconds<10 ? "0" + seconds : seconds}</span>;
    };

    return (
        <Link to={"/bonus"} className={[cl.gif, !active && cl.gif_disabled].join(" ")} onClick={play}>
            <div>
                { active
                    ? <div className={cl.text}>{title}</div>
                    : <Countdown date={now} onComplete={completed} renderer={renderer}/>
                }
            </div>
            <div className={[cl.image, active && cl.imageActive].join(" ")}>
                <img src="/image/main/gift.svg" alt="Подарок"/>
            </div>
        </Link>
    );
};

export default Bonus;