import {$auth} from "../config/api.config";

class BotService {
    async get(){
        const {data} = await $auth.get("/bot");
        return data;
    }

    async update(dataFetching){
        const {data} = await $auth.post("/bot/update", dataFetching);
        return data;
    }

}

export default new BotService();
