import {useMutation, useQueryClient} from "@tanstack/react-query";

import {toast} from "sonner";
import BonusService from "../services/bonus.service";

export const useBonus = (navigate, language) =>{
    const queryClient = useQueryClient();

    const {mutate: getBonus} = useMutation({
        mutationKey: ["bonus"],
        mutationFn: async ({id}) => {
            await BonusService.get(id)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: async ()=>{
            queryClient.invalidateQueries({queryKey: ["user"]})
            toast.success(language?.bonusReceived);
            setTimeout(()=>{
                navigate("/")
            }, 2000)
        },
    })
    return {
        getBonus
    }
}