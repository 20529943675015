import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import cl from "./taskItem.module.scss";
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";

const TaskItem = ({task, updateTask, checkText, language}) => {

    const [check, setCheck] = useState(false);
    const [play] = useSound(clickSound)
    const checkComplete = (e) => {
        play()
        e?.stopPropagation()
        e?.preventDefault()
        updateTask({taskId: task.id})
    }

    useEffect(() => {
        if(check && task.type === "invite"){
            updateTask({taskId: task.id})
        }
    }, [check]);

    return (
            <Link
                target={task.link ? "_blank" : "_self"}
                className={task.completed ? [cl.item, cl.itemCompleted].join(" ") : cl.item}
                to={task.link}
                onClick={() => {setCheck(true); play()}}>
            <img className={cl.item__image} src="/image/gift.svg" alt="gift"/>
            <div className={cl.item__column}>
                <div className={cl.item__title}>
                    {task?.location ===  "eng" ? task.titleEnglish : task?.location ===  "ru" ? task.title : language === "ru" ? task.title : task.titleEnglish}
                </div>
                <div className={cl.item__subtitle}>
                    {task?.location ===  "eng" ? task.titleEnglish : task?.location ===  "ru" ? task.title : language === "ru" ? task.title : task.titleEnglish}
                </div>
            </div>
            {(check && !task.completed && task.type !== "invite") ? <div className={cl.check} onClick={e=>checkComplete(e)}>{checkText}</div>:<div className={cl.item__bonus}>+{task.amount} NVT</div>}
        </Link>
    );
};

export default TaskItem;