import {$auth} from "../config/api.config";


class SettingsService {
    async get(name) {
        const {data} = await $auth.get(`/settings?name=${name}`)
        return data
    }
    async update(dataFetch) {
        const {data} = await $auth.post(`/settings/update`, dataFetch)
        return data
    }
}

//eslint-disable-next-line
export default new SettingsService();