import React, {useEffect, useState} from 'react';
import cl from "./popupUpdateLink.module.scss";
import {useLink} from "../../hooks/useLink.hook";

const PopupUpdateLink = ({visible, setVisible, data}) => {

    const [title, setTitle] = useState(data?.title);
    const {updateLink, deleteLink} = useLink()

    useEffect(() => {
        setTitle(data?.title)
    }, [data]);

    return (
        <div className={visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper} onClick={() => setVisible(false)}>
            <div className={cl.body} onClick={(e) => e.stopPropagation()}>
                <div className={cl.title}>Редактировать ссылку</div>
                <input type={"text"} value={title} onChange={(e) => setTitle(e.target.value)} className={cl.input}/>
                <button className={cl.btn} onClick={() => {
                    if (title !== "") {
                        updateLink({title, id: data?.id});
                        setVisible(false)
                    }
                }}>Обновить
                </button>
                <button className={cl.btnDelete} onClick={() => {
                    if (title !== "") {
                        deleteLink(data?.id);
                        setVisible(false)
                    }
                }}>Удалить
                </button>
            </div>
        </div>
    );
};

export default PopupUpdateLink;