import React, {useEffect, useState} from 'react';

import cl from "./adminBot.module.scss"
import AdminLayout from "../../components/adminLayout/adminLayout";
import {useBot} from "../../hooks/useBot.hook";
import {useMailing} from "../../hooks/useMailing.hook";


const AdminBot = () => {

    const resetMailing = () =>{
        setImage(null)
        setMailing({text: "", textBtn: ""})
    }

    const resetBot = () =>{
        setImageBot(null)
    }

    const [image, setImage] = useState();
    const [imageBot, setImageBot] = useState();

    const {botData, botUpdate} = useBot(resetBot)
    const {sendMailing} = useMailing(resetMailing)

    const [bot, setBot] = useState(botData)

    const [mailing, setMailing] = useState({text: "", textBtn: ""})





    useEffect(()=>{
        setBot(botData)
    }, [botData])

    const handleFileChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleImageBotChange = (event) => {
        setImageBot(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('image', image);
        formData.append('text', mailing.text);
        formData.append('textBtn', mailing.textBtn);

        sendMailing(formData)
    };

    const handleBot = () =>{

        const formData = new FormData();
        formData.append('image', imageBot);
        formData.append('text', bot.text);
        formData.append('textBtn', bot.textBtn);
        formData.append('textEng', bot.textEng);
        formData.append('textBtnEng', bot.textBtnEng);
        botUpdate(formData)
    }

    return (
        <AdminLayout>
            <div className={cl.block}>
                <div className={cl.title}>Настройка бота</div>
                <div className={cl.settings}>
                    <input type="text" className={cl.input} value={bot?.text}
                           onChange={(e) => setBot({...bot, text: e.target.value})} placeholder={"Текст (rus)"}/>
                    <input type="text" className={cl.input} value={bot?.textBtn}
                           onChange={(e) => setBot({...bot, textBtn: e.target.value})}
                           placeholder={"Текст кнопки (rus)"}/>
                    <input type="text" className={cl.input} value={bot?.textEng}
                           onChange={(e) => setBot({...bot, textEng: e.target.value})} placeholder={"Текст (eng)"}/>
                    <input type="text" className={cl.input} value={bot?.textBtnEng}
                           onChange={(e) => setBot({...bot, textBtnEng: e.target.value})}
                           placeholder={"Текст кнопки (eng)"}/>
                    <div className={cl.mailingUpload}>
                        <div>{imageBot ? imageBot?.name : "Загрузить картикну"}</div>
                        <input type="file" className="file" onChange={handleImageBotChange}/>
                    </div>
                    <button className={cl.mailingBtn} onClick={handleBot}>Сохранить</button>
                </div>
                <div className={cl.title}>Рассылка</div>
                <form className={cl.mailing} onSubmit={handleSubmit}>
                    <textarea className={cl.textarea} rows={3} value={mailing?.text} onChange={(e) => setMailing({...mailing, text: e.target.value})} placeholder={"Текст рассылки"}/>
                    <input type="text" value={mailing?.textBtn} onChange={(e)=>setMailing({...mailing, textBtn: e.target.value})} className={cl.input} placeholder={"Текст кнопки"}/>
                    <div className={cl.mailingUpload}>
                        <div>{image ? image?.name : "Загрузить картикну"}</div>
                        <input type="file" className="file" onChange={handleFileChange}/>
                    </div>
                    <button className={cl.mailingBtn}>Разослать</button>
                </form>
            </div>
        </AdminLayout>
    );
};

export default AdminBot;